import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useTranslations } from 'next-intl';
import React, { useContext } from 'react';

import { MenuItemType } from '@/config-schema/navigation';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import { useHeaderLinks } from '@/hooks/config/use-header-links';
import { RedirectModalContext } from '@/root-provider/redirect-modal-provider';
import { cn } from '@/utils/tailwind';

import { maxItemsForSingleLayer } from './constants';
import {
  NavigationMenuItem,
  NavigationMenuItemGtmProps,
} from './navigation-menu-item';
import { customNavigation } from './utils';

export function HeaderNavigation(
  props: RewardsCentralConfiguration['application']['components']['header'] &
    RewardsCentralConfiguration['application']['theme'] & {
      features: RewardsCentralConfiguration['application']['features'];
      navigationConfiguration: RewardsCentralConfiguration['application']['components']['navigation'];
    },
) {
  const { headerLinks, earnHeaderLinks, redeemHeaderLinks } = useHeaderLinks(
    props.features,
    props.navigationConfiguration,
  );

  return headerLinks.length > maxItemsForSingleLayer ? (
    <TwoLayersNavigation
      earnGroupItems={earnHeaderLinks}
      redeemGroupItems={redeemHeaderLinks}
      {...props}
    />
  ) : (
    <OneLayerNavigation menuItems={headerLinks} {...props} />
  );
}

// TODO: Create a design-system-component for 1-level navigation item
function OneLayerNavigation(
  props: RewardsCentralConfiguration['application']['components']['header'] &
    RewardsCentralConfiguration['application']['theme'] & {
      menuItems: MenuItemType[];
    },
) {
  const { setShowRedirectModal } = useContext(RedirectModalContext);
  const tenantHubConfig = useTenantHubConfig();
  const tenantHubHeaderFontColor =
    tenantHubConfig?.header_font_color ?? props.color;

  return (
    <NavigationMenu.Root>
      <NavigationMenu.List className="lg:flex lg:gap-x-6">
        {props.menuItems.map((item) => {
          const dataGtm = {
            event: 'homepage_navigation_menu_click',
            group_0: item.groupType,
            type_0: item.itemType,
          } satisfies NavigationMenuItemGtmProps;

          return (
            <NavigationMenuItem
              data-testid="header-navigation-link"
              key={item.itemType}
              style={{ color: tenantHubHeaderFontColor }}
              href={item.href}
              menuItem={item}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                customNavigation(item, event, setShowRedirectModal);
              }}
              dataGtm={JSON.stringify(dataGtm)}
              className="gtm:homepage_navigation_menu_item px-0 py-0"
            />
          );
        })}
        <NavigationMenu.Indicator
          className="transition-width h-1 transform duration-300 ease-in-out"
          style={{ backgroundColor: tenantHubHeaderFontColor ?? props.color }}
        />
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}

function TwoLayersNavigation(
  props: RewardsCentralConfiguration['application']['components']['header'] &
    RewardsCentralConfiguration['application']['components']['navigation'] &
    RewardsCentralConfiguration['application']['theme'] & {
      earnGroupItems: MenuItemType[];
      redeemGroupItems: MenuItemType[];
    },
) {
  const t = useTranslations('headerNavigation');
  const { setShowRedirectModal } = useContext(RedirectModalContext);
  const tenantHubConfig = useTenantHubConfig();
  const tenantHubHeaderBackgroundColor = tenantHubConfig?.header_color;
  const tenantHubHeaderFontColor = tenantHubConfig?.header_font_color;

  const menuItemCssStyle = { color: tenantHubHeaderFontColor ?? props.color };
  const navigationMenuContentStyles = cn(
    'border-t border-neutral-400',
    'absolute left-0 top-full w-full py-6',
  );

  const redeemRewardsText = t('redeemRewards');
  const earnMoreText = t('earnMore');

  return (
    <NavigationMenu.Root className="h-full [&>div]:h-full">
      <NavigationMenu.List className="h-full lg:flex lg:gap-x-6">
        {props.redeemGroupItems.length > 0 ? (
          <NavigationMenu.Item
            value="redeem-menu-group"
            className="flex justify-center"
          >
            <NavigationMenu.Trigger
              data-testid="header-redeem-link"
              style={menuItemCssStyle}
            >
              {redeemRewardsText}
            </NavigationMenu.Trigger>
            <NavigationMenu.Content
              className={cn(navigationMenuContentStyles)}
              style={{
                backgroundColor:
                  tenantHubHeaderBackgroundColor ?? props.backgroundColor,
              }}
              data-testid="menu-group-reward"
            >
              <NavigationMenu.Sub className="mx-auto container-responsive">
                <ul className="grid grid-cols-3 gap-x-8 gap-y-4">
                  {props.redeemGroupItems.map((item) => {
                    const dataGtm = {
                      event: 'homepage_navigation_menu_click',
                      group_0: item.groupType,
                      type_0: item.itemType,
                    } satisfies NavigationMenuItemGtmProps;

                    return (
                      <NavigationMenuItem
                        onClick={(
                          event: React.MouseEvent<HTMLAnchorElement>,
                        ) => {
                          customNavigation(item, event, setShowRedirectModal);
                        }}
                        dataGtm={JSON.stringify(dataGtm)}
                        data-testid="header-redeem-link-item"
                        key={item.itemType}
                        menuItem={item}
                        href={item.href}
                        displayMode="full"
                        className="gtm:homepage_navigation_menu_item h-full"
                        style={{
                          ...(tenantHubHeaderFontColor
                            ? { color: tenantHubHeaderFontColor }
                            : {}),
                        }}
                      />
                    );
                  })}
                </ul>
              </NavigationMenu.Sub>
            </NavigationMenu.Content>
          </NavigationMenu.Item>
        ) : null}

        {props.earnGroupItems.length > 0 ? (
          <NavigationMenu.Item
            value="earn-menu-group"
            className="flex justify-center"
          >
            <NavigationMenu.Trigger
              style={menuItemCssStyle}
              data-testid="header-earn-link"
            >
              {earnMoreText}
            </NavigationMenu.Trigger>
            <NavigationMenu.Content
              className={cn(navigationMenuContentStyles)}
              style={{
                backgroundColor:
                  tenantHubHeaderBackgroundColor ?? props.backgroundColor,
              }}
              data-testid="menu-group-earn"
            >
              <NavigationMenu.Sub className="mx-auto container-responsive">
                <ul className="grid grid-cols-3 gap-8">
                  {props.earnGroupItems.map((item) => (
                    <NavigationMenuItem
                      data-testid="header-earn-link-item"
                      key={item.itemType}
                      menuItem={item}
                      href={item.href}
                      displayMode="full"
                      className="gtm:homepage_navigation_menu_item"
                    />
                  ))}
                </ul>
              </NavigationMenu.Sub>
            </NavigationMenu.Content>
          </NavigationMenu.Item>
        ) : null}
        <NavigationMenu.Indicator
          className="transition-width bottom-0 h-[2px] transform duration-300 ease-in-out"
          style={{ backgroundColor: tenantHubHeaderFontColor ?? props.color }}
        />
      </NavigationMenu.List>
      <NavigationMenu.Viewport />
    </NavigationMenu.Root>
  );
}
