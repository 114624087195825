'use client';

import { ReactNode, useState } from 'react';

import { authStateEnum } from '@/auth/auth-type';
import { useHermes } from '@/auth/use-hermes';
import { SpinnerIcon } from '@/components/icons/spinner-icon/spinner-icon';
import { useGTMTenantReady } from '@/hooks/utils/use-gtm-tenant-ready';
import { BootstrapData } from '@/schema/bootstrap.schema';
import { AppEnv, useCustomizationConfigStore } from '@/store/store';
import { useIsPublicPage } from '@/utils/page-url';

export function BootstrapProvider({
  children,
  bootstrapData,
  appEnv,
}: {
  children: ReactNode;
  bootstrapData: BootstrapData | null;
  appEnv: AppEnv;
}) {
  const isPublicPage = useIsPublicPage();
  const configStore = useCustomizationConfigStore((state) => {
    return {
      updateBootstrapData: state.updateBootstrapData,
      bootstrapData: state.bootstrapData,
      updateAppEnv: state.updateAppEnv,
    };
  });
  const hermes = useHermes();
  useGTMTenantReady(configStore.bootstrapData?.tenantId, hermes.user?.sub);

  // use useState here to pass customizationConfig to children
  // otherwise the config is only available on client
  // in dev mode + StrictMode this function is executed twice
  useState(() => {
    configStore.updateBootstrapData(bootstrapData);
    configStore.updateAppEnv(appEnv);
  });

  // show the children only when the auth is initialized or the failure page
  return (
    <>
      {children}

      {
        // Do not show the spinner on the failure page
        hermes.authState !== authStateEnum.enum.login && !isPublicPage ? (
          <SpinnerIcon />
        ) : null
      }
    </>
  );
}
