'use client';

import { useFlagsStatus } from '@unleash/nextjs';
import { useEffect, type PropsWithChildren } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useAuthStore } from '@/app/auth-store';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { symbolCookieL10n } from '@/i18n/constants';
import { localeConfigContext } from '@/i18n/contexts/locale-config-context/locale-config-context';
import type { LocaleConfig } from './types';

interface Props extends PropsWithChildren, LocaleConfig {}

export function LocaleConfigProvider(props: Props) {
  const { children, ...value } = props;
  const { defaultLocale, locale, locales } = value;

  const dispatch = useAuthStore(useShallow((state) => state.dispatch));
  const flagStatus = useFlagsStatus();
  const isI18nFlagEnabled = useFlagsReady('rc_fe_i18n');
  const isI18nEnabled =
    (globalThis.document?.cookie &&
      new RegExp(`${symbolCookieL10n}=1`).test(globalThis.document.cookie)) ||
    isI18nFlagEnabled;

  useEffect(() => {
    if (isI18nEnabled && defaultLocale && locale && locales) {
      dispatch({
        type: 'SET_USER_LOCALE_CONFIG',
        payload: {
          defaultLocale,
          locale,
          locales,
        },
      });
    }
  }, [defaultLocale, dispatch, isI18nEnabled, locale, locales]);

  /**
   * NOTE(rongsen): TLDR; Do not render the children until the flags are ready.
   *
   * This is required to prevent all fetches from using the old locale config
   * before the new locale config is set. As the existing architecture does not
   * re-trigger all fetches when the locale config changes, we need to ensure
   * that the locale config is set before the children are rendered.
   *
   * FYI, this [JIRA ticket] will resolve the re-fetch issue.
   *
   * [JIRA ticket]: https://kaligo.atlassian.net/browse/RCF-1746
   */
  if (flagStatus.flagsReady) {
    return (
      <localeConfigContext.Provider value={value}>
        {children}
      </localeConfigContext.Provider>
    );
  }

  return null;
}
