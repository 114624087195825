'use client';

import { useSize } from 'ahooks';
import { WontFix } from 'global';
import { useTranslations } from 'next-intl';
import { AnchorHTMLAttributes, DetailedHTMLProps, useContext } from 'react';

import { Container } from '@/components/container';
import { Markdown } from '@/components/markdown';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import { useHeaderLinks } from '@/hooks/config/use-header-links';
import { useFooterRcLinks } from '@/hooks/footer/use-footer-rc-links';
import { RedirectModalContext } from '@/root-provider/redirect-modal-provider';
import { useIsFailurePage } from '@/utils/page-url';
import { cn } from '@/utils/tailwind';

import { customNavigation } from '../header/utils';
import { RcLink } from '../rc-link';
import { AscendaLink } from './ascenda-link/ascenda-link';
import { BrandLogoLink } from './brand-logo-link/brand-logo-link';
import { linkClasses } from './constants';
import { FooterLink } from './footer-link/footer-link';

export type FooterProps =
  RewardsCentralConfiguration['application']['components']['footer'] &
    RewardsCentralConfiguration['application']['theme'] & {
      features: RewardsCentralConfiguration['application']['features'];
      navigationConfiguration: RewardsCentralConfiguration['application']['components']['navigation'];
    };

export function Footer(props: FooterProps) {
  const isFailurePage = useIsFailurePage();

  return isFailurePage ? null : <FooterUi {...props}></FooterUi>;
}

export function FooterUi(props: FooterProps) {
  const isClient = typeof window !== 'undefined';
  const size = useSize(isClient ? document.body : undefined);

  const t = useTranslations('footer');
  const tenantHubConfig = useTenantHubConfig();
  const isConfigAvailable = !!props;
  const footerCopyStyles = cn({
    [props?.textAlign]: isConfigAvailable,
    [props?.textDecoration]: isConfigAvailable,
    [props?.textTransform]: isConfigAvailable,
  });

  const allRightsReservedText = t('allRightsReserved_year', {
    year: new Date().getFullYear(),
  });

  const footerLogo =
    props.logo === 'inverted' ? props.invertedLogo : props.brandLogo;

  // Logo: Use brandLogo from the theme, if footerLogo was not supplied
  return (
    <footer
      className={cn('pb-8 pt-6 text-footer lg:pt-8', footerCopyStyles)}
      style={{
        backgroundColor: tenantHubConfig?.footer_color ?? props.backgroundColor,
      }}
    >
      <Container className="flex flex-col gap-8">
        {props.variant === 'footer-1' || props.variant === 'footer-2' ? (
          <VariantOneAndTwoFooterLinks
            brandLogo={footerLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
          />
        ) : null}
        {props.variant === 'footer-3' || props.variant === 'footer-4' ? (
          <VariantThreeAndFourFooterLinks
            brandLogo={footerLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
          />
        ) : null}
        {props.variant === 'footer-5' || props.variant === 'footer-6' ? (
          <VariantFiveAndSixFooterLinks
            brandLogo={footerLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
          />
        ) : null}
        {props.variant === 'footer-7' || props.variant === 'footer-8' ? (
          <VariantSevenAndEightFooterLinks
            brandLogo={footerLogo}
            textAlign={props.textAlign}
            hideBranding={props.hideBranding}
            features={props.features}
            navigationConfiguration={props.navigationConfiguration}
            variant={props.variant}
          />
        ) : null}
        {props.variant === 'footer-2' ||
        props.variant === 'footer-4' ||
        props.variant === 'footer-6' ||
        props.variant === 'footer-7' ||
        props.variant === 'footer-8' ? (
          <Markdown
            className={cn(props.textAlign)}
            overrides={{
              a: ({
                node,
                ...props
              }: {
                node: WontFix;
                props: DetailedHTMLProps<
                  AnchorHTMLAttributes<HTMLAnchorElement>,
                  HTMLAnchorElement
                >;
              }) => {
                // only support open external link for now
                const isHrefUrl =
                  // @ts-expect-error: TODO fix later
                  props?.href?.startsWith('http');
                // DO NOT use RcLink or Next.js Link component here
                // https://kaligo.atlassian.net/browse/BUG-2482
                return isHrefUrl ? (
                  <a
                    {...props}
                    className={linkClasses}
                    target="_blank"
                    rel="noreferrer noopener"
                  />
                ) : (
                  <a {...props} className={linkClasses} />
                );
              },
            }}
          >
            {props?.content}
          </Markdown>
        ) : null}

        {props.hideBranding ? null : <p>{allRightsReservedText}</p>}
      </Container>
    </footer>
  );
}

function VariantOneAndTwoFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
}: {
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
}) {
  const footerRcLinks = useFooterRcLinks();

  return (
    <div>
      <ul
        className={cn(
          'flex flex-wrap gap-x-6 gap-y-2 lg:flex-row lg:gap-x-10',
          {
            'justify-around': textAlign === 'text-center',
          },
        )}
        role="contentinfo"
      >
        {footerRcLinks.map((d) => (
          <li key={d.href}>
            <FooterLink data-testid="footer-link" href={d.href}>
              <p>{d.translation}</p>
            </FooterLink>
          </li>
        ))}

        <li className="mt-8 hidden flex-grow justify-end lg:mt-0 lg:block">
          <div className="flex w-full flex-col justify-center gap-4 lg:flex-row lg:justify-end">
            <BrandLogoLink logo={brandLogo} />
            <AscendaLink
              hideBranding={hideBranding}
              className={textAlign}
              data-testid="footer-logo"
            />
          </div>
        </li>
      </ul>
      <div className="mt-8 flex-grow justify-end lg:mt-0 lg:block lg:hidden">
        <div className="flex w-full flex-col justify-center gap-4 lg:flex-row lg:justify-end">
          <BrandLogoLink logo={brandLogo} />
          <AscendaLink
            hideBranding={hideBranding}
            className={textAlign}
            data-testid="footer-logo"
          />
        </div>
      </div>
    </div>
  );
}

function VariantThreeAndFourFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
}: {
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
}) {
  const footerRcLinks = useFooterRcLinks();

  return (
    <>
      <ul
        className={cn(
          'flex flex-wrap gap-x-6 gap-y-2 lg:flex-row lg:gap-x-10',
          { 'justify-around': textAlign === 'text-center' },
        )}
        role="contentinfo"
      >
        <li className="hidden flex-grow lg:flex">
          <div className="flex w-full flex-col justify-center gap-4 lg:flex-row lg:justify-start">
            <BrandLogoLink logo={brandLogo} />
            <AscendaLink
              hideBranding={hideBranding}
              className={textAlign}
              data-testid="footer-logo"
            />
          </div>
        </li>

        {footerRcLinks.map((d) => (
          <li key={d.href}>
            <FooterLink href={d.href}>
              <p>{d.translation}</p>
            </FooterLink>
          </li>
        ))}
      </ul>
      <div className="mt-8 flex-grow justify-end lg:mt-0 lg:block lg:hidden">
        <div
          className={cn(
            'flex flex-col gap-4 lg:flex-row',
            { 'justify-center': textAlign === 'text-center' },
            { 'items-start': textAlign === 'text-left' },
          )}
        >
          <BrandLogoLink logo={brandLogo} />
          <AscendaLink
            hideBranding={hideBranding}
            className={textAlign}
            data-testid="footer-logo"
          />
        </div>
      </div>
    </>
  );
}

function VariantFiveAndSixFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
}: {
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
}) {
  const footerRcLinks = useFooterRcLinks();

  return (
    <>
      <ul
        className={cn(
          'flex flex-wrap gap-x-6 gap-y-2 lg:mt-0 lg:flex-row lg:gap-x-10',
          { 'justify-center': textAlign === 'text-center' },
        )}
        role="contentinfo"
      >
        {footerRcLinks.map((d) => (
          <li key={d.href}>
            <FooterLink href={d.href}>
              <p>{d.translation}</p>
            </FooterLink>
          </li>
        ))}
      </ul>

      <div
        className={cn('mt-8 flex w-full flex-grow flex-col gap-6', {
          'items-center': textAlign === 'text-center',
        })}
      >
        <BrandLogoLink logo={brandLogo} />
        <AscendaLink hideBranding={hideBranding} data-testid="footer-logo" />
      </div>
    </>
  );
}

function VariantSevenAndEightFooterLinks({
  brandLogo,
  textAlign,
  hideBranding,
  features,
  variant,
  navigationConfiguration,
}: {
  textAlign: RewardsCentralConfiguration['application']['components']['footer']['textAlign'];
  brandLogo: RewardsCentralConfiguration['application']['theme']['brandLogo'];
  hideBranding: RewardsCentralConfiguration['application']['components']['footer']['hideBranding'];
  features: RewardsCentralConfiguration['application']['features'];
  navigationConfiguration: RewardsCentralConfiguration['application']['components']['navigation'];
  variant: 'footer-7' | 'footer-8';
}) {
  const { headerLinks, needHelpLinks } = useHeaderLinks(
    features,
    navigationConfiguration,
  );
  const { setShowRedirectModal } = useContext(RedirectModalContext);

  const gridClassName = cn(
    'grid grid-cols-3 lg:grid-flow-col lg:grid-cols-none lg:grid-rows-4',
    'gap-x-6 gap-y-2 lg:gap-x-16 lg:gap-y-4',
  );
  const rcLinkClassName = 'font-normal text-base capitalize';

  return (
    <div
      className={cn(
        'flex flex-col lg:flex-row lg:justify-between',
        'gap-8 lg:gap-0',
        {
          'lg:flex-row-reverse': variant === 'footer-8',
        },
      )}
    >
      <div className="flex flex-col gap-8 lg:flex-row lg:gap-16">
        <div className={gridClassName}>
          {headerLinks.map((link) => (
            <RcLink
              key={link.itemType}
              href={link.href}
              className={rcLinkClassName}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                customNavigation(link, event, setShowRedirectModal);
              }}
            >
              {link.translation}
            </RcLink>
          ))}
        </div>

        <div className={gridClassName}>
          {needHelpLinks.map((link) => (
            <RcLink
              key={link.translation}
              href={link.href}
              className={rcLinkClassName}
            >
              {link.translation}
            </RcLink>
          ))}
        </div>
      </div>

      <div
        className={cn(
          'flex flex-col lg:flex-row lg:items-center',
          'h-fit gap-2 lg:w-fit lg:gap-4',
          {
            'items-center': textAlign === 'text-center',
            'w-auto items-start': textAlign === 'text-left',
            'items-end': textAlign === 'text-right',
          },
        )}
      >
        <BrandLogoLink logo={brandLogo} />
        <AscendaLink
          hideBranding={hideBranding}
          className={textAlign}
          data-testid="footer-logo"
        />
      </div>
    </div>
  );
}
