'use client';

import { FlagProvider, useUnleashClient, type IToggle } from '@unleash/nextjs';
import { ComponentProps, PropsWithChildren, useEffect, useState } from 'react';

import { useUser } from '@/app/auth-store';
import { UNLEASH_REFRESH_INTERVAL_IN_SECONDS } from '@/utils/unleash/constants';

const PRODUCTION_UNLEASH_KEY =
  '*:production.ed9dc866a8362d926412ad673806fed91176182036fdca279f983b42';
const STAGING_UNLEASH_KEY =
  '*:development.23acaf6ca5485c9ed7a23766b5fd67b8c96e4439247906c9a8b2d070';

const UNLEASH_URL = 'https://us.app.unleash-hosted.com/usee0016/api/frontend';

type UnleashConfig = NonNullable<ComponentProps<typeof FlagProvider>['config']>;

interface Props extends PropsWithChildren {
  environment: string;
  tenantId: string;
  flagOverrides?: Record<string, boolean>;
}

export default function FlagProviderWrapper({
  environment,
  children,
  tenantId,
  flagOverrides,
}: Props) {
  const [config] = useState<UnleashConfig>(() => {
    return {
      environment,
      url: UNLEASH_URL,
      appName: 'rc-us',
      context: {
        tenant_id: tenantId,
        properties: {
          /**
           * @deprecated - use `host` instead.
           *
           * This line only executes on the client side
           */
          domain: typeof window === 'undefined' ? '' : window.location.hostname,
          host: globalThis.location?.hostname,
        },
      },
      clientKey:
        environment === 'production'
          ? PRODUCTION_UNLEASH_KEY
          : STAGING_UNLEASH_KEY,
      refreshInterval: UNLEASH_REFRESH_INTERVAL_IN_SECONDS,
      bootstrap: flagOverrides ? kvToToggles(flagOverrides) : undefined,
    };
  });

  return (
    <FlagProvider config={config}>
      <UpdateFlagProvider tenantId={tenantId}>{children}</UpdateFlagProvider>
    </FlagProvider>
  );
}

function UpdateFlagProvider({
  tenantId,
  children,
}: Pick<Props, 'children' | 'tenantId'>) {
  const user = useUser();

  /**
   * NOTE(rongsen): Use `client.setContextField()` instead of `client.updateContext()`
   * because `updateContext()` will override the initial values we set in the config
   * in `FlagProviderWrapper`.
   *
   * See this [issue] for more details.
   *
   * [issue]: https://github.com/Unleash/proxy-client-react/issues/89
   */
  const client = useUnleashClient();

  const userId = user?.sub;

  useEffect(() => {
    if (tenantId) {
      /**
       * fixme(rongsen): To revisit this when we standardize the feature flags.
       *
       * High chances is we do not need this.
       *
       * old comment:
       * we can include additional properties in context
       * but the type definition is not correct
       * @deprecated - use tenantId instead
       */
      client.setContextField('tenant_id', tenantId);
    }

    if (userId) {
      client.setContextField('userId', userId);
    }

    /**
     * NOTE: This is a TE specific context field.
     *
     * Read this [thread] for more details.
     *
     * [thread]: https://kaligo.slack.com/archives/C053LMXDBSM/p1726137468337609?thread_ts=1725878028.788429&cid=C053LMXDBSM
     */
    client.setContextField('tenant_is_rc', 'true');
  }, [client, tenantId, userId]);

  return children;
}

function kvToToggles(kv: Record<string, boolean>): IToggle[] {
  return Object.entries(kv).map(([name, enabled]) => ({
    name,
    enabled,
    variant: { name: 'default', enabled },
    impressionData: false,
  }));
}
